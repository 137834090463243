import React, { useEffect } from "react"

import styled from "styled-components"
import { v4 as uuid } from "uuid"

import Layout from "components/layout"
import { VehicleMap } from "components/vehicles/vehicle-map"

import { useGlobalDispatch, useGlobalState } from "utils/state-utils"

const MapWrapper = styled.div`
  height: 100vh;
  display: grid;
`

const Page = () => {
  const dispatch = useGlobalDispatch()
  const {
    fetchers: {
      vehicles: { data: vehicles },
    },
    vehiclePollingSubscriptions,
  } = useGlobalState()

  useEffect(() => {
    const subscriptionId = uuid()
    dispatch({
      vehiclePollingSubscriptions: [
        { id: subscriptionId, frequencyInSeconds: 2 },
      ],
    })
    return () => {
      const mutableSubscription = vehiclePollingSubscriptions
      const editedSubscription = mutableSubscription.filter(
        (s) => s.id != subscriptionId
      )
      dispatch({ vehiclePollingSubscriptions: editedSubscription })
    }
  }, [])

  return (
    <Layout title="TV Dashboard" hideContent>
      <MapWrapper>
        <VehicleMap vehicles={vehicles} featureSize="large" />
      </MapWrapper>
    </Layout>
  )
}

export default Page
